<template>
  <div class="mx-auto  text-center text-[32px] font-black bg-cover relative h-[170px]">
    <div
        :style="backgroundStyles"
        class="absolute inset-0 bg-cover bg-top bg-no-repeat"
    ></div>
    <div class="absolute inset-0 bg-white/40"></div> <!-- 半透明層 -->
    <div class="relative z-10">
      <h1 class="font-bold pc:text-[28px] mobile:text-[14px] pt-6 mb-4">{{ $t('menu.upper_title') }}</h1>
<!--      <h2 class="my-2 pc:text-[36px] mobile:text-[24px]">{{ $t('menu.title') }}</h2>-->
<!--      <p class="mb-5 font-normal pc:text-[28px] mobile:text-[14px]">{{ $t('menu.sub_title') }}</p>-->
      <SearchBar :is-index="true"></SearchBar>
    </div>
  </div>
</template>

<script setup>
import SearchBar from "@/components/SearchIndex/SearchBarWrapper/SearchBar.vue";

const settingStore = useSettingStore();
const isMobile = computed(() => settingStore.isMobile);
const img = useImage();

const backgroundStyles = computed(() => {
  const imgUrl = isMobile.value
      ? img('/background/searchBarM170.jpg', {sizes: '100vw', format: 'webp', quality: 80})
      : img('/background/searchBar170.jpg', {sizes: '100vw', format: 'webp', quality: 80});
  return {backgroundImage: `url('${imgUrl}')`};
});
</script>

<style scoped
       lang="scss">
/*
!*1920 版本*!
@media (min-width: 1365px) {
  .search-bar-wrapper{
    background-image: url("@/assets/image/background/searchBar.png");
  }
}

!*1366 版本*!
@media (max-width: 1365px) {
  .search-bar-wrapper{
    background-image: url("@/assets/image/background/searchBar.png");
  }
}

!*390 版本*!
@media (max-width: 767px) {
  .search-bar-wrapper{
    background-image: url("@/assets/image/background/searchBarM.png");
  }
}
*/


</style>
