/*
1. 攝影棚
2. 忠孝復興捷運站附近，固定2組
3. 行天宮捷運站附近，固定2組
* */

const newSpaceListQueryData = {
    "pagination": {
        "dir": 1,
        "limit": 20,
        "page": 1,
        "sort": "created_date"
    }
}

const photoStudio = {
    "pagination": {
        "dir": 0,
        "limit": 4,
        "page": 1,
        "sort": "created_date"
    },
    filter_options: {
        category_ids: {
            '2': [13]
        }
    }
}
const zhongxiaoFuxingStation1 = {
    "pagination": {
        "dir": 0,
        "limit": 4,
        "page": 1,
        "sort": "location"
    },
    filter_options: {
        "geo_search": {
            "longitude": 121.5394226856529,
            "latitude": 25.04064821708269,
            "width": 4000,
            "height": 4000
        }
    }
}

const hsingTianTempleStation1 = {
    "pagination": {
        "dir": 0,
        "limit": 4,
        "page": 1,
        "sort": "location"
    },
    filter_options: {
        "geo_search": {
            "longitude": 121.53321241516821,
            "latitude": 25.060117562272325,
            "width": 4000,
            "height": 4000
        }
    }
}

const trendSpaceListQueryDataList = [
    photoStudio,
    zhongxiaoFuxingStation1,
    hsingTianTempleStation1
]


const richerDaySpaceListQueryData = {
    "pagination": {
        "dir": 1,
        "limit": 20,
        "page": 1,
        "sort": "created_date"
    },
    filter_options: {
        keyword: '日富',
        "rent_time": {
            "rent_type": 0,
            "begin_time": "",
            "end_time": ""
        },
        "geo_search": {
            "longitude": 0,
            "latitude": 0,
            "width": 0,
            "height": 0,
            "radius": 0
        },
        "equipment_ids": [],
        "service_ids": [],
        "city_ids": [],
        "category_ids": {}
    }


}
const like268SpaceListQueryData = {
    "pagination": {
        "dir": 0,
        "limit": 20,
        "page": 1,
        "sort": "created_date"
    },
    filter_options: {
        keyword: '萊客共享空間',
        "rent_time": {
            "rent_type": 0,
            "begin_time": "",
            "end_time": ""
        },
        "geo_search": {
            "longitude": 0,
            "latitude": 0,
            "width": 0,
            "height": 0,
            "radius": 0
        },
        "equipment_ids": [],
        "service_ids": [],
        "city_ids": [],
        "category_ids": {}
    }


}
const trendDaySpaceListQueryData1 = {
    "pagination": {
        "dir": 1,
        "limit": 1,
        "page": 1,
        "sort": "created_date"
    },
    filter_options: {
        keyword: '廢墟BAR'
    }
}
const trendDaySpaceListQueryData2 = {
    "pagination": {
        "dir": 1,
        "limit": 1,
        "page": 1,
        "sort": "created_date"
    },
    filter_options: {
        keyword: '社團法人NYCN國際美容美甲協會'
    }
}
const trendDaySpaceListQueryData3 = {
    "pagination": {
        "dir": 1,
        "limit": 1,
        "page": 1,
        "sort": "created_date"
    },
    filter_options: {
        keyword: 'POM共享商務空間'
    }
}
const trendDaySpaceListQueryData4 = {
    "pagination": {
        "dir": 1,
        "limit": 1,
        "page": 1,
        "sort": "created_date"
    },
    filter_options: {
        keyword: 'Dcar甜'
    }
}
const trendSpaceListQueryDataList2 = [
    trendDaySpaceListQueryData1,
    trendDaySpaceListQueryData2,
    trendDaySpaceListQueryData3,
    trendDaySpaceListQueryData4,
]


export {newSpaceListQueryData, trendSpaceListQueryDataList, richerDaySpaceListQueryData, trendSpaceListQueryDataList2,like268SpaceListQueryData};
