<template>
  <div class="text-[20px] font-bold">{{ title }}</div>
  <div class="flex flex-wrap min-w-[80px] w-fit">
    <div v-for="item in unitList"
         :key="item.id || item.city_id">
      <div
          class="city-unit"
          @click="onSearch(item.id || item.city_id, item.slug)"
      >
        {{ item.name || item.city_name }}
      </div>
      <div
          v-if="getFilteredSpaceList(item).length"
          class="flex items-center"
      >
        <div
            v-for="space in getFilteredSpaceList(item)"
            :key="space.id"
            @click="clickRecommendSpace(space.id)"
            class="mr-3 text-[16px] cursor-pointer"
        >
          {{ space.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {useGoTo} from "@/composables/useGoTo";
import {useSearchWithStore} from "@/composables/useSearchWithStore";

const searchQueryStore = useSearchQueryStore();
const {goToSpacePage, goWithQuery, go} = useGoTo();
const {handleSearchWithStore} = useSearchWithStore();

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  unitList: {
    type: Array,
    default: () => [],
  },
  searchStore: {
    type: Function,
  },
  recommendSpaceList: {
    type: Object,
    default: () => ({}),
  }
});

function onSearch(id, slug = '') {
  console.log(props);

  if (slug) {
    go('/list/' + slug)

  } else if (id) {
    searchQueryStore.initQueryDate()
    props.searchStore([id])
    goWithQuery('/search-result', {})
    handleSearchWithStore()
  }
  /*  searchQueryStore.initQueryDate()
    props.searchStore([id])
    goWithQuery('/search-result', {})

    handleSearchWithStore()*/


}

const clickRecommendSpace = (spaceId) => {
  goToSpacePage(spaceId);
};

// 計算過濾後的 spaceList
const getFilteredSpaceList = (item) => {
  return (
      props.recommendSpaceList[item.city_id] ||
      props.recommendSpaceList[item.id] ||
      []
  );
};
</script>

<style scoped
       lang="scss">
div.city-unit {
  @apply my-2 mr-[5px] px-[8px] py-[5px] w-fit border rounded-xl border-osn-grey-03 text-[16px] cursor-pointer;
}
</style>
